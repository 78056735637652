export const GoogleMapsPlaceSeperatedEnum = {
  ROUTE: 'route',
  STREET_NUMBER: 'street_number',
  POSTAL_CODE: 'postal_code',
  LOCALITY: 'locality',
  COUNTRY: 'country',
} as const

export const GoogleMapsPlaceSeperatedProperties = Object.values(GoogleMapsPlaceSeperatedEnum)

export type GoogleMapsPlaceSeperatedProperty = typeof GoogleMapsPlaceSeperatedEnum[keyof typeof GoogleMapsPlaceSeperatedEnum]

export type GoogleMapsSeperated = {
  [GoogleMapsPlaceSeperatedEnum.ROUTE]: string
  [GoogleMapsPlaceSeperatedEnum.STREET_NUMBER]?: string
  [GoogleMapsPlaceSeperatedEnum.POSTAL_CODE]: string
  [GoogleMapsPlaceSeperatedEnum.LOCALITY]: string
  [GoogleMapsPlaceSeperatedEnum.COUNTRY]: string
}
