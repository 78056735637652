import { AdresTypeEnum } from 'constants/Adres'
import type { LocatieSelectOption } from 'constants/Locatie'

export default [
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Aarschot',
    adres: 'Statieplein 19, 3200 Aarschot, België',
    postal_code: '3200',
    locality: 'Aarschot',
    country: 'België',
    lng: 4.824040000000001,
    lat: 50.98440999999999,
    adresAlias: [
      'Statieplein 1, 3200 Aarschot, België',
      'Statieplein, 3200 Aarschot, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Antwerpen-Centraal',
    adres: 'Koningin Astridplein 27, 2018 Antwerpen, België',
    country: 'België',
    postal_code: '2018',
    locality: 'Antwerpen',
    lng: 4.421241999999999,
    lat: 51.21686,
    adresAlias: [
      'Koningin Astridplein 1, 2018 Antwerpen, België',
      'Koningin Astridplein, 2018 Antwerpen, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Antwerpen-Noorderkempen',
    adres: 'Oudaenstraat, 2960 Brecht, België',
    country: 'België',
    postal_code: '2960',
    locality: 'Brecht',
    lng: 4.632857300000001,
    lat: 51.35745730000001,
    adresAlias: [
      'Oudaenstraat 1, 2960 Brecht, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Blankenberge',
    adres: 'Leopold III-Plein, 8370 Blankenberge, België',
    country: 'België',
    postal_code: '8370',
    locality: 'Blankenberge',
    lng: 3.1336659,
    lat: 51.31258099999999,
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Brugge',
    adres: 'Stationsplein 5, 8000 Brugge, België',
    country: 'België',
    postal_code: '8000',
    locality: 'Brugge',
    lng: 3.2178295,
    lat: 51.1976874,
    adresAlias: [
      'Stationsplein 1, 8000 Brugge, België',
      'Stationsplein, 8000 Brugge, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Brussel-Centraal',
    adres: 'Europakruispunt, 1000 Brussel, België',
    country: 'België',
    postal_code: '1000',
    locality: 'Brussel',
    lng: 4.3570964,
    lat: 50.845504,
    adresAlias: [
      'Europakruispunt 1, 1000 Brussel, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Brussel-Noord',
    adres: 'Vooruitgangstraat 76, 1030 Schaarbeek, België',
    country: 'België',
    postal_code: '1030',
    locality: 'Schaarbeek',
    lng: 4.3570964,
    lat: 50.845504,
    adresAlias: [
      'Vooruitgangstraat 1, 1030 Schaarbeek, België',
      'Vooruitgangstraat, 1030 Schaarbeek, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Brussel-Zuid',
    adres: 'Fonsnylaan 47B, 1060 Sint-Gillis, België',
    country: 'België',
    postal_code: '1060',
    locality: 'Sint-Gillis',
    lng: 4.335454700000001,
    lat: 50.8360867,
    adresAlias: [
      'Fonsnylaan 47, 1060 Sint-Gillis, België',
      'Fonsnylaan, 1060 Sint-Gillis, België',
      'Zuidlaan, 1060 Sint-Gillis, België',
      'Fonsnylaan 47B, 1060 Brussel, België',
      'Fonsnylaan 47, 1060 Brussel, België',
      'Fonsnylaan, 1060 Brussel, België',
      'Zuidlaan, 1060 Brussel, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'De Panne',
    adres: 'Stationsplein 9, 8660 De Panne, België',
    country: 'België',
    postal_code: '8660',
    locality: 'De Panne',
    lng: 2.6027563,
    lat: 51.0774341,
    adresAlias: [
      'Stationsplein 1, 8660 De Panne, België',
      'Stationsplein, 8660 De Panne, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Diest',
    adres: 'Turnhoutsebaan 1, 3290 Diest, België',
    country: 'België',
    postal_code: '3290',
    locality: 'Diest',
    lng: 5.051203999999999,
    lat: 50.9924695,
    adresAlias: [
      'Turnhoutsebaan, 3290 Diest, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Gent-Sint-Pieters',
    adres: 'Kon. Maria Hendrikaplein 1, 9000 Gent, België',
    country: 'België',
    postal_code: '9000',
    locality: 'Gent',
    lng: 3.7108572,
    lat: 51.036234,
    adresAlias: [
      'Kon. Maria Hendrikaplein, 9000 Gent, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Hasselt',
    adres: 'Stationsplein 2, 3500 Hasselt, België',
    country: 'België',
    postal_code: '3500',
    locality: 'Hasselt',
    lng: 5.327436099999999,
    lat: 50.9309932,
    adresAlias: [
      'Stationsplein 1, 3500 Hasselt, België',
      'Stationsplein, 3500 Hasselt, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Herentals',
    adres: 'Stationsplein 1, 2200 Herentals, België',
    country: 'België',
    postal_code: '2200',
    locality: 'Herentals',
    lng: 4.828894199999999,
    lat: 51.1810719,
    adresAlias: [
      'Stationsplein 1/BUS 1, 2200 Herentals, België',
      'Stationsplein, 2200 Herentals, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Ieper',
    adres: 'Colaertplein 35, 8900 Ieper, België',
    country: 'België',
    postal_code: '8900',
    locality: 'Ieper',
    lng: 2.876631499999999,
    lat: 50.84904520000001,
    adresAlias: [
      'Colaertplein, 8900 Ieper, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Knokke',
    adres: 'Lippensplein 25, 8300 Knokke-Heist, België',
    country: 'België',
    postal_code: '8300',
    locality: 'Knokke-Heist',
    lng: 3.286664499999999,
    lat: 51.3390651,
    adresAlias: [
      'Lippensplein, 8300 Knokke-Heist, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Kortrijk',
    adres: 'Stationsplein 8, 8500 Kortrijk, België',
    country: 'België',
    postal_code: '8500',
    locality: 'Kortrijk',
    lng: 3.2643836,
    lat: 50.8245806,
    adresAlias: [
      'Stationsplein, 8500 Kortrijk, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Leuven',
    adres: 'Martelarenplein 16, 3000 Leuven, België',
    country: 'België',
    postal_code: '3000',
    locality: 'Leuven',
    lng: 4.7156895,
    lat: 50.8813682,
    adresAlias: [
      'Martelarenplein, 3000 Leuven, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Lokeren',
    adres: 'Stationsplein, 9160 Lokeren, België',
    country: 'België',
    postal_code: '9160',
    locality: 'Lokeren',
    lng: 3.985282999999999,
    lat: 51.107103,
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Mechelen',
    adres: 'Koning Albertplein 2, 2800 Mechelen, België',
    country: 'België',
    postal_code: '2800',
    locality: 'Mechelen',
    lng: 4.482325200000001,
    lat: 51.017622,
    adresAlias: [
      'Koning Albertplein, 2800 Mechelen, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Mol',
    adres: 'Statieplein 1, 2400 Mol, België',
    country: 'België',
    postal_code: '2400',
    locality: 'Mol',
    lng: 5.115170200000001,
    lat: 51.1905257,
    adresAlias: [
      'Statieplein, 2400 Mol, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Oostende',
    adres: 'Natiënkaai 1, 8400 Oostende, België',
    country: 'België',
    postal_code: '8400',
    locality: 'Oostende',
    lng: 2.9258874,
    lat: 51.2293966,
    adresAlias: [
      'Natiënkaai 1/BUS 1, 8400 Oostende, België',
      'Natiënkaai, 8400 Oostende, België',
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Tienen',
    adres: 'Vierde Lansierslaan 84, 3300 Tienen, België',
    country: 'België',
    postal_code: '3300',
    locality: 'Tienen',
    lng: 4.9264361,
    lat: 50.8077306,
    adresAlias: [
      'Grijpenlaan, 3300 Tienen, België'
    ],
  },
  {
    adresType: AdresTypeEnum.STATION,
    name: 'Zaventem',
    adres: 'Perronstraat, 1930 Zaventem, België',
    country: 'België',
    postal_code: '1930',
    locality: 'Zaventem',
    lng: 4.507104500000001,
    lat: 50.8833294,
    adresAlias: Array.from({ length: 25 }, (_, index) => `Perronstraat ${index + 1}, 1930 Zaventem, België`),
  },
] as LocatieSelectOption[]
