import { AdresTypeEnum } from 'constants/Adres'
import type { LocatieSelectOption } from 'constants/Locatie'

export default [
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJ7XVSxL7dw0cR5CV_Dj5OUSQ',
    adres: 'Brussels Airport (BRU), Leopoldlaan, 1930 Zaventem, België',
    name: 'Zaventem',
    link: '/luchthavenvervoer/luchthavenvervoer-zaventem',
    lat: 50.900999,
    lng: 4.485574,
    postal_code: '1930',
    locality: 'Zaventem',
    country: 'België',
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJCeXJQCb8wEcRYw2sVrK9Xj0',
    adres: 'Liège Airport (LGG), Rue de l’Aéroport, 4460 Grâce-Hollogne, België',
    name: 'Luik',
    link: '/luchthavenvervoer/luchthavenvervoer-luik',
    lat: 50.641159,
    lng: 5.447912,
    postal_code: '4460',
    locality: 'Grâce-Hollogne',
    country: 'België',
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJSTiRGSApwkcRdG2Lsuqp7Qw',
    adres: 'Brussels South Charleroi Airport (CRL), Rue des Frères Wright 8, 6041 Charleroi, België',
    name: 'Charleroi',
    link: '/luchthavenvervoer/luchthavenvervoer-charleroi',
    lat: 50.462537,
    lng: 4.458821,
    postal_code: '6041',
    locality: 'Charleroi',
    country: 'België',
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJS0sqwWSv3EcR3VNKEu8Zql4',
    adres: 'Nieuwpoortsesteenweg 889, 8400 Oostende, België',
    name: 'Oostende',
    link: '/luchthavenvervoer/luchthavenvervoer-oostende',
    lat: 51.2041938,
    lng: 2.8698128,
    postal_code: '8400',
    locality: 'Oostende',
    country: 'België',
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJoRVJ_Vf3w0cRmcqeCv33EYQ',
    adres: 'Luchthavenlei, 2100 Antwerpen, België',
    name: 'Antwerpen',
    link: '/luchthavenvervoer/luchthavenvervoer-antwerpen',
    lat: 51.2041938,
    lng: 2.8698128,
    postal_code: '2100',
    locality: 'Antwerpen',
    country: 'België',
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJH4aptQ0ww0cRmnSp_AveEpI',
    adres: 'Luchthavenstraat 1, 8560 Wevelgem, België',
    name: 'Kortrijk-Wevelgem',
    link: '/luchthavenvervoer/luchthavenvervoer-kortrijk',
    lat: 51.2041938,
    lng: 2.8698128,
    postal_code: '8560',
    locality: 'Wevelgem',
    country: 'België',
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJLRb94DThxUcRiPHO8YMV1cc',
    adres: 'Amsterdam Airport Schiphol (AMS), Evert van de Beekstraat 202, 1118 CP Schiphol, Nederland',
    name: 'Schiphol',
    link: '/luchthavenvervoer/luchthavenvervoer-schiphol',
    lat: 52.310539,
    lng: 4.768274,
    postal_code: '1118 CP',
    locality: 'Schiphol',
    country: 'Nederland',
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJD8ehowvbxkcROiMX95VI9Y4',
    adres: 'Luchthavenweg 25, 5657 EA Eindhoven, Nederland',
    name: 'Eindhoven',
    link: '/luchthavenvervoer/luchthavenvervoer-eindhoven',
    lat: 51.458369,
    lng: 5.392056,
    postal_code: '5657 EA',
    locality: 'Eindhoven',
    country: 'Nederland',
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJHTtq-rF15kcRIoTbQ9feeJ0',
    adres: 'Luchthaven Orly (ORY), 94390 Orly, Frankrijk',
    name: 'Parijs-Orly',
    link: '/luchthavenvervoer/luchthavenvervoer-parijs-orly',
    lat: 48.726243,
    lng: 2.365247,
    postal_code: '94390',
    locality: 'Orly',
    country: 'Frankrijk',
    tolkosten: 40,
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJW89MjgM-5kcRLKZbL5jgKwQ',
    adres: 'Luchthaven Parijs-Charles de Gaulle (CDG), 95700 Roissy-en-France, Frankrijk',
    name: 'Parijs-Charles de Gaulle',
    link: '/luchthavenvervoer/luchthavenvervoer-parijs-charles-de-gaulle',
    lat: 49.009691,
    lng: 2.547925,
    postal_code: '95700',
    locality: 'Roissy-en-France',
    country: 'Frankrijk',
    tolkosten: 40,
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJN35I6yrIuEcRT-7AUdwf9pQ',
    adres: 'Flughafen Düsseldorf (DUS), Flughafenstraße 120, 40474 Düsseldorf, Duitsland',
    name: 'Dusseldorf',
    link: '/luchthavenvervoer/luchthavenvervoer-dusseldorf',
    lat: 51.287615,
    lng: 6.766791,
    postal_code: '40474',
    locality: 'Düsseldorf',
    country: 'Duitsland',
  },
  {
    adresType: AdresTypeEnum.LUCHTHAVEN,
    place_id: 'ChIJeflCVHQLvUcRMfP4IU3YdIo',
    adres: 'Frankfurt Airport (FRA), 60547 Frankfurt am Main, Duitsland',
    name: 'Frankfurt',
    link: '/luchthavenvervoer/luchthavenvervoer-frankfurt',
    lat: 50.037933,
    lng: 8.562152,
    postal_code: '60547',
    locality: 'Frankfurt am Main',
    country: 'Duitsland',
  },
] as LocatieSelectOption[]
