export const AdresTypeEnum = {
  LUCHTHAVEN: 'LUCHTHAVEN',
  ADRES: 'ADRES',
  STATION: 'STATION',
  HAVEN: 'HAVEN',
  HOTEL: 'HOTEL',
} as const

export const AdresTypes = Object.values(AdresTypeEnum)

export type AdresType = typeof AdresTypeEnum[keyof typeof AdresTypeEnum]
