import type { LocatieSelectOption } from 'constants/Locatie'

import { HAVENS, HOTELS, LUCHTHAVENS, STATIONS } from 'constants/Locaties'

type FindAdresParameter = string
  | Pick<LocatieSelectOption, 'place_id'>
  | Pick<LocatieSelectOption, 'adres'>
  | Pick<LocatieSelectOption, 'name'>

const safeAdresSearch = (value: string) => value?.toLowerCase().replace(/[\s;,-]/g, '')
const removeCountry = (adres: string, country: string) => {
  let result = String(adres || '').toLowerCase()
  if (country) result = result.replace(country.toLowerCase(), '')
  return safeAdresSearch(result)
}

const findFunction = (value: FindAdresParameter) => (el: LocatieSelectOption) => {
  if (!value) return false

  if (typeof value === 'string') {
    const lower = value.toLowerCase()
    return (
      el.place_id?.toLowerCase() === lower
      || removeCountry(el.adres, el.country) === removeCountry(lower, el.country)
      || !!el.adresAlias?.some(alias => removeCountry(alias, el.country) === removeCountry(lower, el.country))
      || el.name?.toLowerCase() === lower
    )
  }

  if ('place_id' in value && value.place_id && el.place_id) {
    return el.place_id.toLowerCase() === value.place_id.toLowerCase()
  }

  if ('adres' in value && value.adres && el.adres) {
    if (removeCountry(el.adres, el.country) === removeCountry(value.adres, el.country)) return true
    if (el.adresAlias && el.adresAlias.some(alias => removeCountry(alias, el.country) === removeCountry(value.adres, el.country))) return true
  }

  if ('name' in value && value.name && el.name) {
    return el.name.toLowerCase() === value.name.toLowerCase()
  }

  return false
}

export const findLuchthaven = (value: FindAdresParameter) => {
  if (!value) return null
  return LUCHTHAVENS.find(findFunction(value))
}

export const findStation = (value: FindAdresParameter) => {
  if (!value) return null
  return STATIONS.find(findFunction(value))
}

export const findHaven = (value: FindAdresParameter) => {
  if (!value) return null
  return HAVENS.find(findFunction(value))
}

export const findHotel = (value: FindAdresParameter) => {
  if (!value) return null
  return HOTELS.find(findFunction(value))
}
