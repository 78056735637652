import { AdresTypeEnum } from 'constants/Adres'

export const isAdres = (obj: { adresType?: string }) => obj?.adresType === AdresTypeEnum.ADRES

export const isLuchthaven = (obj: { adresType?: string }) => obj?.adresType === AdresTypeEnum.LUCHTHAVEN

export const isStation = (obj: { adresType?: string }) => obj?.adresType === AdresTypeEnum.STATION

export const isHaven = (obj: { adresType?: string }) => obj?.adresType === AdresTypeEnum.HAVEN

export const isHotel = (obj: { adresType?: string }) => obj?.adresType === AdresTypeEnum.HOTEL
