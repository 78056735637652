export * from 'constants/index'
export * from 'functions/index'
export type * from 'types/index'

import {bereken as v19} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v19/bereken'
import {bereken as v20} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v20/bereken'
import {bereken as v21} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v21/bereken'
import {bereken as v22} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v22/bereken'
import {bereken as v23} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v23/bereken'
import {bereken as v24} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v24/bereken'
import {bereken as v25} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v25/bereken'
import {bereken as v26} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v26/bereken'
import {bereken as v27} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v27/bereken'
import {bereken as v28} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v28/bereken'
import {bereken as v29} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v29/bereken'

import {bereken as v1001} from 'berekeningen/TAXILUCHTHAVEN/v1001/bereken'
import {bereken as v1002} from 'berekeningen/TAXILUCHTHAVEN/v1002/bereken'
import {bereken as v1003} from 'berekeningen/TAXILUCHTHAVEN/v1003/bereken'
import {bereken as v1004} from 'berekeningen/TAXILUCHTHAVEN/v1004/bereken'
import {bereken as v1005} from 'berekeningen/TAXILUCHTHAVEN/v1005/bereken'
import {bereken as v1006} from 'berekeningen/TAXILUCHTHAVEN/v1006/bereken'

import {BerekeningResult, BerekeningsParamsDTOType} from 'types/Berekening'

export const BEREKENINGEN_BY_COMPANY = {
  LUCHTHAVENVERVOERKEVIN: {
    v19,
    v20,
    v21,
    v22,
    v23,
    v24,
    v25,
    v26,
    v27,
    v28,
    v29,
  },
  TAXILUCHTHAVEN: {
    v1001,
    v1002,
    v1003,
    v1004,
    v1005,
    v1006,
  },
}

export type COMPANY_KEY = keyof typeof BEREKENINGEN_BY_COMPANY

export const COMPANIES = Object.keys(BEREKENINGEN_BY_COMPANY) as COMPANY_KEY[]

export const LATEST_VERSION_BY_COMPANY = COMPANIES.reduce<Record<COMPANY_KEY, string>>(
  (acc, company) => {
    const versions = Object.keys(BEREKENINGEN_BY_COMPANY[company] || {})
    if (versions.length > 0) {
      const version = String(versions.pop() || '').replace('v', '')
      if (version) acc[company] = version
    }
    return acc
  },
  {} as Record<COMPANY_KEY, string>,
)

export const LATEST_BEREKENING_BY_COMPANY = {
  LUCHTHAVENVERVOERKEVIN:
    BEREKENINGEN_BY_COMPANY.LUCHTHAVENVERVOERKEVIN[
      `v${LATEST_VERSION_BY_COMPANY.LUCHTHAVENVERVOERKEVIN}` as keyof typeof BEREKENINGEN_BY_COMPANY.LUCHTHAVENVERVOERKEVIN
    ],
  TAXILUCHTHAVEN:
    BEREKENINGEN_BY_COMPANY.TAXILUCHTHAVEN[
      `v${LATEST_VERSION_BY_COMPANY.TAXILUCHTHAVEN}` as keyof typeof BEREKENINGEN_BY_COMPANY.TAXILUCHTHAVEN
    ],
}

export const COMPANY_MAPPING: Record<string, string> = {
  BAIORO: 'LUCHTHAVENVERVOERKEVIN',
  WATKOSTEENTAXI: 'LUCHTHAVENVERVOERKEVIN',
  WATKOSTLUCHTHAVENVERVOER: 'LUCHTHAVENVERVOERKEVIN',
  TAXIBOEKEN: 'LUCHTHAVENVERVOERKEVIN',
  TAXICALCULATOR: 'TAXILUCHTHAVEN',
  LUCHTHAVENVERVOERCALCULATOR: 'LUCHTHAVENVERVOERKEVIN',
}

export const getBerekening = (
  companyArg: string,
  versionArg?: string,
): ((BERKENINGS_CONTEXT_DTO: BerekeningsParamsDTOType) => Readonly<BerekeningResult>) => {
  if (COMPANY_MAPPING[companyArg]) {
    return getBerekening(COMPANY_MAPPING[companyArg], versionArg)
  }

  // Get latest version:
  if (!versionArg) {
    const companyKey = companyArg as keyof typeof LATEST_BEREKENING_BY_COMPANY

    if (!LATEST_BEREKENING_BY_COMPANY[companyKey]) throw new Error(`Company ${companyKey} not found`)

    return LATEST_BEREKENING_BY_COMPANY[companyKey]
  }

  // Get specific version:
  const companyKey = companyArg as keyof typeof BEREKENINGEN_BY_COMPANY
  const versionKey = versionArg as keyof (typeof BEREKENINGEN_BY_COMPANY)[typeof companyKey]
  const versionKeyPrefixed = `v${versionArg}` as keyof (typeof BEREKENINGEN_BY_COMPANY)[typeof companyKey]

  if (!BEREKENINGEN_BY_COMPANY[companyKey]) throw new Error(`Company ${companyKey} not found`)

  const result =
    BEREKENINGEN_BY_COMPANY[companyKey][versionKey] || BEREKENINGEN_BY_COMPANY[companyKey][versionKeyPrefixed]

  if (!result) throw new Error(`Version ${String(versionKey)} not found`)
  return result
}
