import type { LocatieSelectOption } from 'constants/Locatie'

// Ibis
// Premier Inn
// Radisson Blu
// Holiday Inn
// Hilton
// Mercure
// Novotel
// Travelodge
// Marriott
// Sheraton

// La Butte aux Bois - Lanaken, België
// Hotel Dukes' Palace - Brugge, België
// La Réserve Resort - Knokke, België
// Botanic Sanctuary Antwerp - Antwerpen, België
// Hotel Amigo - Brussel, België
// Steigenberger Wiltcher's - Brussel, België
// Kasteel van Ordingen - Sint-Truiden, België
// Hotel Heritage - Brugge, België
// Radisson Blu Royal Hotel - Brussel, België
// Martin's Château du Lac - Genval, België
// Hotel Sanglier des Ardennes - Durbuy, België
// Hotel Astoria - Brussel, België
// Hotel Le Plaza - Brussel, België
// Hotel Métropole - Brussel, België
// The Hotel - Brussel, België

export default [] as LocatieSelectOption[]
