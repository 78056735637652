import type { LocatieSelectOption } from 'constants/Locatie'

export default [] as LocatieSelectOption[]


/*
export default [
  {
    name: 'Haven van Antwerpen',
    adres: 'Straatsburgdok Noordkaai 2, 2030 Antwerpen, België',
  },
  {
    name: 'Haven van Zeebrugge',
    adres: 'Isabellalaan 1, 8380 Brugge, België',
  },
  {
    name: 'Haven van Gent',
    adres: 'John F. Kennedylaan 32, 9042 Gent, België',
  },
  {
    name: 'Haven van Oostende',
    adres: 'Slijkensesteenweg 2, 8400 Oostende, België',
  },
  {
    name: 'Haven van Luik - Port autonome de Liège (PAL)',
    adres: 'Quai de Maestricht 14, 4000 Liège, België',
  },
  {
    name: 'Haven van Namen - Port autonome de Namur (PAN)',
    adres: 'Rue des Peupliers 2, 5100 Namur, België',
  },
  {
    name: 'Haven van Charleroi - Port autonome de Charleroi (PAC)',
    adres: 'Rue de Marcinelle 31, 6000 Charleroi, België',
  },
  {
    name: 'Haven van La Louvière - Port autonome du Centre et de l\'Ouest (PACO)',
    adres: 'Rue Mercure 1, 7110 La Louvière, België',
  },
]
*/
